@font-face {
  font-family: 'Avenir Next Medium';
  src: url('fonts/AvenirNextLTPro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir Next Regular';
  src: url('fonts/AvenirNextLTProRegular.woff') format('woff'),
    url('fonts/AvenirNextLTProRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url('fonts/AvenirNextLTProDemi.woff') format('woff'),
    url('fonts/AvenirNextLTProDemi.woff2') format('woff2');
}

@font-face {
  font-family: 'Avenir Next Bold';
  src: url('fonts/AvenirNextLTProBold.woff') format('woff'),
    url('fonts/AvenirNextLTProBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Avenir Next Cyr W00 Regular';
  src: url('fonts/AvenirNextCyrRegular.woff2') format('woff2'),
    url('fonts/AvenirNextCyrRegular.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Cyr W00 Medium';
  src: url('fonts/AvenirNextCyrMedium.woff2') format('woff2'),
    url('fonts/AvenirNextCyrMedium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Cyr W00 Demi';
  src: url('fonts/AvenirNextCyrDemi.woff2') format('woff2'),
    url('fonts/AvenirNextCyrDemi.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Cyr W00 Bold';
  src: url('fonts/AvenirNextCyrBold.woff2') format('woff2'),
    url('fonts/AvenirNextCyrBold.woff') format('woff');
}
